  /* --------- */
/* info */
/* --------- */

.info-about-description {
    color: rgb(215, 230, 220);
    text-align: left;
    padding-top: 220px;
    padding-bottom: 40px;
    /* padding-bottom: 60px; */
    font-size: 1.2em !important;
    text-align: left;
    /* justify-content: flex-start; */
  }
  
  .info-about-body {
    color: white !important;
    text-align: left;
    /* padding-top: 220px; */
    padding-bottom: 0px;
    font-size: 1.2em !important;
    text-align: left;
    /* justify-content: flex-start; */
  }
  
  .info-about-container {
    padding-top: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .info-about-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .crowd {
    padding-bottom: 20px !important;
    width: 100%;
   object-fit: cover;
   padding-top: 20px;
   margin: auto;
  }
  
  .caption {
  margin: auto;
  }
  
  @media screen and (max-width: 575px) {
    .info-about-description { 
      padding-bottom: 50px;
    }

    .info-about-container {
      padding-top: 80px;
    }
    .caption {
      text-align: center;

      }
  
  }
  
 