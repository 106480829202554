.app-about-header {
  padding-top: 200px; 
  padding-bottom: 50px;
  display: flex;
  /* justify-content: center;
  align-items: center;
  text-align: center; */
  color: #1ce783;
}

.app-about-header p {
  font-size: 1.2em;
 
}

.app-about-description {
  padding-bottom: 20px;
  font-size: 1.2em; 
  line-height: 1.6; 
  color: #333; 
}

.app-about-description h1 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #1ce783;
}

.app-about-footer {
  padding-top: 50px; 
  padding-bottom: 50px;
  display: flex;
  /* justify-content: center;
  align-items: center;
  text-align: center; */
  color: #1ce783;
}

.app-about-footer p {
  font-size: 1.2em;
 
}

  .app-phone {
    padding-bottom: 50px;
    font-size: 36px;
    font-weight: 800;
    justify-content: center; 
    align-items: center;
  }

  .app-about-container {
    padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

  
  .app-about-screens {
    
        padding-bottom: 10px;
        justify-content: center; 
        align-items: center;
        text-align: center;
    }

    .phone {
      width: 40%;
      height: auto;
      object-fit: contain;
      padding-bottom: 50px;
      margin: auto;
    }
    
   
  @media screen and (max-width: 575px) {
    .app-about-description {
      padding-bottom: 10px !important;
    }
  
    .app-phone {
      padding-top: 10px !important;
    }
  
    .app-about-phone {
      padding-bottom: 10px !important;
      padding-top: 10px !important;
    }

    .phone {  
      padding-bottom: 40px;     
    }
  }
  
  