.rabbit-about-links2 {
    color: white !important;
    text-align: left;
    padding-top: 220px;
    
    font-size: 1.2em !important;
    text-align: left;
   
  }
  
  .rabbit-about-description {
    color: white !important;
    text-align: left;
    padding-top: 220px;
    padding-bottom: 40px;
    font-size: 1.2em !important;
    text-align: left;
    /* justify-content: flex-start; */
  }

  .rabbit-about-description2 {

  align-items: center;
    padding-top: 220px;
   
  }

  .therapist {
    padding-bottom: 60px !important;
    width: 100%;
   object-fit: cover;
   padding-top: 20px;
}

  .statement {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .reason {
    padding-top: 60px;
    /* padding-bottom: 60px; */
  }

  @media screen and (max-width: 768px) {
    .rabbit-about-description2 {
      display: flex;
      align-items: center;
        padding-top: 20px;
        padding-bottom: 80px;
      }
  }

 
  