.brain {
    width: 100%;
    height: auto;
    object-fit: contain;
    padding-bottom: 20px;
  }


.platform-about-description {
    text-align: left;
    padding-top: 290px;
    padding-bottom: 80px;
    font-size: 1.5em !important;
    text-align: left;
 justify-content: center; 
 align-items: center;
  }

  .platform-about-description2 {
    text-align: left;
    padding-top: 200px;
    padding-bottom: 80px;
    font-size: 1.2em !important;
    text-align: left;
 justify-content: center; 
  align-items: center;
  }

  .platform-about-description3 {
    text-align: left;
    padding-top: 340px;
    padding-bottom: 40px;
    font-size: 1.2em !important;
    text-align: left;
 justify-content: center; 
  }

  .platform-about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .platform-about-hidden {
    padding-top: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .platform-about-mission {
    /* padding-top: 40px; */
    padding-bottom: 50px;
    font-size: 36px;
    font-weight: 800;
   
  }


  .platform-about-links {
    color: white !important;
    text-align: left;
    padding-top: 220px;
    padding-bottom: 80px;
    font-size: 1.2em !important;
    text-align: left;
    /* justify-content: flex-start; */
  }

  .platform-subhead {
    justify-content: flex-start;
    text-align: left;
    font-weight: 400;
  }

  .philosophy-subhead {
    justify-content: flex-start;
    text-align: left;
    font-weight: 400;
    padding-bottom: 0px;
  }

  .platform-about-journal {
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding-top: 50px; */
    
    padding-bottom: 50px;  
    font-size: 1.2em !important;
    font-weight: 400;
  }

  .philosophy-links{
    color: white !important;
   
    padding-top: 50px;
    /* padding-bottom: 80px; */
    font-size: 1.2em !important;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 575px) {
    .platform-about-links {
      padding-top: 20px;
    }
    .platform-about-description {
      text-align: left;
      padding-top: 220px;
      padding-bottom: 20px;
      font-size: 1.2em !important;
      text-align: left;
   justify-content: center; 
   align-items: center;
    }
    .platform-about-description2 {
     
      padding-top: 50px;
      /* padding-bottom: 80px;
      font-size: 1.2em !important;
      text-align: left;
   justify-content: center; 
    align-items: center; */
    }

}