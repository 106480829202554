  .home-subhead {
    justify-content: flex-start;
    text-align: left;
    font-weight: 400;
    padding-bottom: 50px;
  }

  .home-title-main {
    font-size: 2.9em;
    color: #1ce783;
  }
  
  .home-title-sub {
    font-size: 1.9em;
  }
   
  .home-about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 50px;
  }

  .home-about-flourish {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 50px;
  }

  .home-about-screens {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 0;
    
  }
  
  .home-about-mission {
    font-size: 36px;
    font-weight: 800;
    margin: 0; /* Remove default paragraph margin */
  }
  
  .home-about-journal {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 100px;  
    font-size: 24px;
    font-weight: 400;
  }
  
  .home-about-invest {
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 16px;
    font-weight: 400;
  }

  .home-about-privacy {
    padding-bottom: 100px;
    font-size: 16px;
    font-weight: 400;
  }
  
  .home-about-doorways {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
  }
  .home-about-door {
    padding-top: 20px;
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: 400;
    
  }
  .home-about-door2 {
    padding-top: 20px; 
    font-size: 20px;
    font-weight: 400;  
  }
  
  .home-about-description {
    color: white !important;
    text-align: left;
    padding-top: 300px;
    padding-bottom: 80px;
    font-size: 1.2em !important;
    text-align: left;
  }

  .app-links-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
      
  .app-icon,
  .google-icon {
    width: auto;
    height: 45px;
    margin-right: 12px;
    object-fit: contain;
  }
      
  .screens {
        width: 100%;
        height: auto;
        object-fit: contain; 
  }
      
  .appHomeScreen {
      padding-bottom: 20px !important;
       width: 50%; 
       height: auto; 
       padding-top: 150px;
    }

  .man {
        padding-bottom: 60px !important;
        width: 50%;
       object-fit: cover;
       padding-top: 20px;
  }

  .waitlist-app-row {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  
  .waitlist-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .app-links-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 200px;
    
  }

  .google-icon {
    height: 42px;
  }
  
  .waitlist-app-row {
    margin-top: 30px;
  }

  .waitlist-platform-select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    width: 130px; /* Adjust width as needed */
    color: #1ce783;
    background-color: #383535;
  }
 
  .waitlist-email-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
    width: 200px; /* Adjust width as needed */
    color: #1ce783;
    background-color: #383535;
}
.waitlist-email-input::placeholder {
  color: #1ce783; /* Choose your desired color */
}
  .waitlist-button {
    padding: 10px 15px;
    background-color: #1ce783; 
    border: none;
    border-radius: 4px;
    color: black;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.waitlist-button:hover {
    background-color: #3eda02; 
}

@media screen and (max-width: 768px) {
  .home-about-description {
    padding-top: 220px;
    padding-bottom: 40px;
  }

  .man {
    padding-bottom: 60px !important;
    width: 100%;
   object-fit: cover;
   padding-top: 20px;
}



  .waitlist-app-row {
    order: 3;
  }

  .app-links-container {
    justify-content: flex-start;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .appHomeScreen {
    padding-top: 40px;
  }

  .home-title-main {
      font-size: 2.0em; 
    }
  
  .home-title-sub {
      font-size: 1.5em; 
    }
  }
