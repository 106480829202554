
.logo {
    height: 1.4em !important;
    width: 2.5em !important;
  }

.sticky {
    Background-color: #141416d8;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(255, 255, 255, 0.171) !important;
    backdrop-filter: blur(15px) 
  }
  
  .navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
  }
  
  .navbar-toggler {
    padding: 0.25rem 1.5rem !important;
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
  
  .navbar-toggler span {
    display: block !important;
    background-color: #ffffffd8!important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0 !important;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
  }
  
  @media screen and (max-width: 767px) {
    .navbar {
      padding: 1rem 2rem !important;
      font-size: 1.4rem !important;
      background-color: #141416d8 !important;
    }
    .navbar-nav .nav-item a::after {
      display: none !important;
    }
  }
  .navbar-brand {
    color: rgb(250, 250, 250) !important;
  }
  
  /* seeker hover */
  .navbar-brand a:hover {
    color: #1ce783 
  }
  
  /* section color */
  .navbar-nav .nav-link {
    color: rgb(255, 255, 255) !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  
  /* seeker link button */
  .nav-link {
    padding: 0.8rem 1rem !important;
  }

  .Navbar .dropdown-toggle-link::after {
    background: #1ce783 !important;
}

  
  @media screen and (max-width: 575px) {
    .nav-link {
      padding: 0.7rem 1rem !important;
    }
  }
  
  .navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
  }
  
  .navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
  }
  
  .navbar-nav .nav-item a::after {
    content: '';
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #1ce783 !important;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
  }
  
  .navbar-nav .nav-item a:hover::after {
    width: 100%;
  }

  /* .Nav .dropdown-toggle-link::after {
    background: #1ce783 !important;
} */

  .dropdown-toggle-link::after {
    content: '';
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #1ce783 !important;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity 0.3s ease-out 0s;
    opacity: 0;; 
}
/* removes the fragment by keeping it hidden */
.dropdown-toggle-link:hover::after {
    opacity: 1; 
    color: #1ce783 !important;
}
  
  .dropdown-link::after {
    content: '';
    position: absolute;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #1ce783 !important;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: transform 0.3s ease-out 0s;
    transform: scaleX(0);
    transform-origin: left center; 
  }

  .dropdown-menu {
    background-color: #000000!important;
  }

  /* animates lines under sublinks */
  .dropdown-item:hover .dropdown-link::after {
    transform: scaleX(1);
  }
  
  .dropdown-link:hover::after {
    transform: scaleX(1);
  }


  .dropdown-item {
    color: #ffffff !important;
    background-color: #000000 !important;
  }

  a.navLinkColor:link,
a.navLinkColor:visited {
  color: rgb(255, 255, 255) !important;
}
a.navLinkColor:hover,
a.navLinkColor:active {
  color: #1ce783 !important;
}