/* play with uncommenting the first elements in this first html section in order to get full screen gradient. */
/* perhaps its fine? it just flashes white on a fast scroll */


/* --------- */
/* Splash Screen */
/* --------- */
/* comment out the above */
/* --------- */
.splashScreen {
  padding-bottom: 20px !important;
  padding-top: 30px;
   width: 50%; 
   /* height: auto;  */
 
}

.home-about-description {
  color: white !important;
  text-align: center;
  padding-top: 50px;
  /* padding-bottom: 80px; */
  font-size: 1.2em !important;
}

.home-about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-about-mission {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 36px;
  font-weight: 800;
}





/* --------- */
/* Old Site */
/* --------- */

.home-about {
  background-image: var(--section-background-color);
  background-size: cover;
  background-position: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  /* flex: 1;
  overflow-y: scroll; */
}

html {
  --section-background-color: linear-gradient(
    0.1turn,
    #000000,
    #242324 50%,
    #000000
  );
   height: 100%;
}

.App {
  text-align: left;
  
}

#about {
  height: 100vh;
}

.homelogo {
  height: 4rem;
  width: 3rem;
  object-fit: contain;
}

h1 {
  font-size: 5rem;
  color: rgb(215, 230, 220);
}

p {
  color: rgb(215, 230, 220);
}

h5 {
  font-size: 2rem;
  color: rgb(213, 213, 223);
}

span {
  /* background: rgb(59, 144, 76); */
  margin-top: 8px;
  font-weight: 800;
  font-size: larger;
  /* color: #5dcf79; */
  color: rgb(213, 213, 223);
  
}

@media screen and (max-width: 575px) {
  h1 {
    font-size: 3rem;
  }
}


/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #141416d8;
  position: fixed;  /* Add this line */
  bottom: 0;
  width: 100%;  /* Add this line */
  padding-top: 10px;
  padding-bottom: 8px;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  text-align: center !important;
}

.footer h3 {
  font-size: 1em;
  color: rgb(255, 255, 255) !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}



@media screen and (max-width: 575px) {
  .footer {
    padding-top: 2px !important;
    padding-bottom: 0 !important;
  }
  .footer-copywright {
    text-align: center !important;
  }
  .footer-body {
    text-align: center !important;
  }
  .footer h3 {
    font-size: .8em;
    margin-top: 0.2em !important;
    margin-bottom: 0.2em !important;
  }
  .footer-icons {
    margin-top: 0.2em !important;
    margin-bottom: 0.2em !important;
    padding: 0 !important;
  }
}




.blockquote-footer {
  color: #ffffff !important;
}


/* unvisited link */
a:link {
  color: #ffffff;
}

/* visited link */
a:visited {
  color: #ffffff;
}

/* mouse over link */
a:hover {
  color: #1ce783
}

/* selected link */
a:active {
  color: #b6a8f5;
}
/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

@media screen and (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}



/* --------- */
/*  Preloader */
/* --------- */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: linear-gradient(0.1turn, #131111, #242324 50%, #111113);
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  /* height: 100vh; */
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.959);
  border-radius: 2px;
  height: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.911);
  border-radius: 2px;
  height: 2px;
}



.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #0c861c !important;
}

