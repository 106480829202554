.research-about-links {
    color: white !important;
    text-align: left;
    padding-top: 220px;
    font-size: 1.2em !important;
    text-align: left;
  }
  
  .research-about-links2 {
    color: white !important;
    text-align: left;
    padding-top: 220px;
    
    font-size: 1.2em !important;
    text-align: left;
   
  }
  
    .research-about-description {
      color: white !important;
      text-align: left;
      padding-top: 220px;
      padding-bottom: 80px;
      font-size: 1.2em !important;
      text-align: left;
      /* justify-content: flex-start; */
    }
  
    @media screen and (max-width: 575px) { 
      .research-about-description {
        padding-top: 20px;
        padding-bottom: 100px;
      }
  
    }