
.resources-about-description {
    color: white !important;
    text-align: left;
    padding-top: 220px;
    /* padding-bottom: 80px; */
    font-size: 1.2em !important;
    text-align: left;
  }

  .resources-about-links {
    color: white !important;
    text-align: left;
    padding-top: 120px;
    /* font-size: 1.2em !important; */
    display: flex;
    align-items: center; /* This centers children vertically */
  }

  .joy {
    padding-bottom: 20px !important;
    width: 100%;
   object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  @media screen and (max-width: 575px) {
    .resources-about-links {
      padding-top: 80px;
    }
  }

